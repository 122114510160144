import React from "react";
import "./App.css";
import { products } from "./products";
import { ProductCard } from "./ProductCard";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import { Layout } from "./Layout";
import { Jumbotron } from "./Jumbotron";

function App() {
  return (
    <Layout>
      <div>
        <div className="container mx-auto mb-8">
          <Jumbotron>
            <h2>فروشگاه قالب و افزونه وردپرس </h2>
            <h1 className="text-3xl font-bold">
              راهکار مطمئن و سریع برای طراحی و توسعه وب‌سایت
            </h1>
          </Jumbotron>
        </div>
      </div>

      <div>
        <div className="container mx-auto">
          <h2 className="text-xl font-bold mb-6">آخرین پلاگین ها</h2>

          <div className="flex flex-wrap">
            <div className="flex flex-wrap gap-8">
              {products.map((product) => (
                <Link to={product.id}>
                  <ProductCard
                    key={product.id}
                    image={product.image}
                    title={product.title}
                    subtitle={product.category}
                    price={product.price}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
