export const products = [
  {
    title: "افزونه باندل محصولات، Yith Woocommerce Product Bundles",
    image:
      "https://files.rtl-theme.com/attachments/2021/12/087876e34430286f69064b366cf454be3644fd0c481088-1050x520.jpg",
    price: "31,600",
    version: "1.14.0",
    id: "yith-woocommerce-product-bundles-wordpress-plugin",
    category: "افزونه فروشگاهی",
  },
  {
    title: "قالب فروشگاه ایرانی نگارشاپ",
    image:
      "https://files.rtl-theme.com/attachments/2022/06/c5d17e9c09658a1f49554511c931777e9469a2047d1396-1050x520.png",
    price: "399,000",
    version: "8.0.0",
    id: "negarshop-woocommerce-wordpress-theme",
    category: "افزونه فروشگاهی",
  },
  {
    title: "افزونه Automatewoo، افزونه ووکامرس اتوماتیک",
    image:
      "https://files.rtl-theme.com/attachments/2022/01/242043cf9c11737d04bdcd182cf665675543d3373f2655-1050x520.png",
    price: "119,000",
    version: "5.5.17",
    id: "automatewoo-wordpress-plugin",
    category: "افزونه مارکتینگ",
  },
  {
    title: "افزونه Wallet Woocommerce، افزونه کیف پول ووکامرس",
    image:
      "https://files.rtl-theme.com/attachments/2021/09/109b6f0097ce3203f33a09391ff36555003f9146809bdb-1050x520.png",
    price: "99,000",
    version: "2.9.10",
    id: "wallet-woocommerce-plugin",
    category: "افزونه فروشگاهی",
  },
];
