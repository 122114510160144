import { ReactNode } from "react";
import bg from "./00.svg";

export const Jumbotron = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-[200px] flex flex-col items-center justify-center gap-y-4 bg-slate-100 rounded-xl overflow-hidden relative">
      <img
        src={bg}
        className="h-full w-full absolute z-[1] object-cover opacity-5"
      />
      <div className="relative z-[2] flex flex-col items-center justify-center gap-y-4">
        {children}
      </div>
    </div>
  );
};
