export const ProductCard = ({ image, title, subtitle, price }: any) => {
  return (
    <div className="bg-white rounded-2xl overflow-hidden pb-5 max-w-[300px]">
      <img src={image} className="mb-4" />

      <div className="flex flex-col gap-y-1 px-4">
        <div className="flex flex-col gap-y-2">
          <h2>{title}</h2>
          <span className="opacity-70">{subtitle}</span>
        </div>
        <hr className="my-2 border-gray-100" />
        <span className="text-sm">
          <strong>{price}</strong> تومان
        </span>
      </div>
    </div>
  );
};
