import logo from "./logo.png";

export const Footer = () => {
  return (
    <div className="mt-10">
      <hr className="mb-6" />
      <div className="container mx-auto text-sm text-slate-600">
        <div className="flex gap-x-8 h-full items-start">
          <div className="h-full flex flex-col">
            <p className="leading-7 mb-4">
              ما در دونو سرویس به شما کمک می کنیم کسب و کار آنلاین خودتان را با
              قالب های وردپرس، افزونه های کاربردی و اسکریپت های حرفه ای سریع،
              ساده و ارزان بسازید. برنامه نویسان دونو سرویس تلاش می کنند راه
              اندازی وب سایت به یک تجربه حرفه ای از کیفیت و خدمات پشتیبانی تبدیل
              شود. مدرن ترین متدهای طراحی وب در قالب هزاران محصول آماده طراحی
              سایت هم اکنون در دسترس شماست.
            </p>

            <div className="flex shrink-0 items-center text-black">
              <img src={logo} className="h-12 w-12 opacity-20 -mr-3" />
              <span className="">
                تمامی حقوق برای سایت دونو سرویس محفوظ است
              </span>
            </div>
          </div>

          <div className="flex w-[120px] h-[140px] bg-slate-200 rounded-2xl flex items-center justify-center shrink-0">
            <a
              referrerPolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=286296&amp;Code=C8GmBZ3pnU1lofN2OMEQ"
            >
              <img
                referrerPolicy="origin"
                src="https://trustseal.enamad.ir/Content/Images/Star/star1.png"
                alt=""
                style={{ cursor: "pointer" }}
                id="C8GmBZ3pnU1lofN2OMEQ"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
