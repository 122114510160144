import { Jumbotron } from "./Jumbotron";
import { Layout } from "./Layout";

export const ContactUs = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <Jumbotron>
          <h1 className="text-3xl font-bold">تماس با ما</h1>
        </Jumbotron>
        <div className=" mx-auto">
          <div className="flex items-center mt-10 gap-x-16  mb-8">
            <div className="flex flex-col mt-6 gap-y-4 leading-8 mb-6">
              <p>
                در صورت بروز هر گونه باگ در کد پلاگین ها و یا مشکل در پرداخت،
                مشکل در استفاده از سایت و یا موارد فنی با ما تماس بگیرید.{" "}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-6 mb-6">
            <div className="bg-white rounded-2xl flex flex-col items-center justify-center shadow-sm gap-y-3 py-6">
              <h3 className="font-bold">تماس با ما</h3>
              <span>021-44031650</span>
            </div>

            <div className="bg-white rounded-2xl flex flex-col items-center justify-center shadow-sm gap-y-3 py-6">
              <h3 className="font-bold">آدرس ایمیل</h3>
              <span>info@dono.services</span>
            </div>

            <div className="bg-white rounded-2xl flex flex-col items-center justify-center shadow-sm gap-y-3 py-6">
              <h3 className="font-bold">کد پستی</h3>
              <span>1481865669</span>
            </div>
          </div>

          <div className="bg-white rounded-2xl flex flex-col items-center justify-center shadow-sm gap-y-3 py-6">
            <h3 className="font-bold">آدرس</h3>
            <span>
              تهران، خیابان فردوس شرق، بین رامین و وفا آذر، پلاک 273 ساختماس
              یاس، واحد 8
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};
