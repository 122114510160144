import { Link } from "react-router-dom";
import logo from "./logo.png";

export const Header = () => {
  return (
    <div className="header py-4 grow bg-white shadow-sm mb-10">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center gap-x-1">
            <img src={logo} className="h-12 w-12" />
            <span className="font-bold text-lg">دونو سرویس</span>
          </Link>
          <ul className="flex gap-x-4">
            <li>
              <Link to="/about-us">درباره ما</Link>
            </li>
            <li>
              <Link to="/contact-us">تماس با ما</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
