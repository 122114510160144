import { useEffect } from "react";
import { useParams } from "react-router";
import { Layout } from "./Layout";
import { products } from "./products";
import { CheckIcon } from "@heroicons/react/outline";

export const Product = () => {
  const { productId } = useParams();
  const product = products.find((product) => product.id === productId);

  useEffect(() => {
    console.log(product);
  }, [product]);

  const handleBuy = () => {
    (async () => {
      window.location.href = `https://sepehr.shaparak.ir:8080`;
      //   const req = await fetch("https://checkout-api.9ah.ir/v1/order", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + localStorage.getItem("token"),
      //     },
      //     body: JSON.stringify({
      //       paymentMethodId: "4e2cee0c-4611-4721-9426-8530154d1af0",
      //       products: {
      //         id: "d9953a75-d9ac-4640-a029-709ae6e80ce0",
      //         metadata: { quantity: 1 },
      //       },
      //     }),
      //   });

      //   const data = await req.json();
    })();
  };

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="flex gap-x-4 mb-6">
          <h1 className="text-xl font-bold">{product?.title}</h1>
          <span className="rounded-full py-1 px-3 bg-slate-100 flex items-center justify-center text-xs">
            {product?.version}
          </span>
        </div>
        <div className="flex gap-x-8">
          <div className="w-4/12 xl:w-3/12">
            <div className="bg-white shadow-sm rounded-2xl px-6 py-4">
              <ul className="mb-8 flex flex-col gap-y-2 text-sm">
                <li>
                  <CheckIcon className="text-green-500 w-5 h-5 inline-flex ml-2" />
                  گارانتی کیفیت راست چین
                </li>
                <li>
                  <CheckIcon className="text-green-500 w-5 h-5 inline-flex ml-2" />
                  ضمانت بازگشت 6 ماهه وجه
                </li>
                <li>
                  <CheckIcon className="text-green-500 w-5 h-5 inline-flex ml-2" />
                  6 ماه پشتیبانی رایگان حرفه ای
                </li>
                <li>
                  <CheckIcon className="text-green-500 w-5 h-5 inline-flex ml-2" />
                  آپدیت رایگان، دسترسی مادام العمر به فایل
                </li>
              </ul>

              <div className="flex justify-between mb-4">
                <span className="text-lg font-medium">قیمت محصول:</span>
                <div className="text-lg text-slate-700">
                  <span className="text-xl text-black">{product?.price} </span>
                  تومان
                </div>
              </div>
              <button
                className="text-white px-4 py-2 bg-indigo-700 rounded-xl w-full h-12 mb-4"
                onClick={handleBuy}
              >
                خرید و تحویل آنی
              </button>
              <hr />
              <div className="mt-4">
                دسته:{" "}
                <span className="text-slate-700">{product?.category}</span>
              </div>
            </div>
          </div>
          <div className="w-8/12 xl:w-9/12">
            <img
              src={product?.image}
              alt={product?.title}
              className="w-full rounded-2xl"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
