import { Jumbotron } from "./Jumbotron";
import { Layout } from "./Layout";

export const AboutUs = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <Jumbotron>
          <h1 className="text-3xl font-bold">درباره ما</h1>
        </Jumbotron>
        <div className="flex items-center mt-10 gap-x-32">
          <div className="flex flex-col mt-6 gap-y-4 leading-8">
            <p>
              ما در دونو سرویس یک تیم خلاق و جوان داریم که دید متفاوتی نسبت به
              وردپرس و پلاگین های آن دارند. ما در دونو سرویس تصمیم گرفتیم در
              کنار سادگی پیشرفته ترین پلاگین های مورد نیاز کاربران ایرانی را
              ایجاد کنیم.
            </p>
            <p>
              تمامی محصولات سایت دارای <strong>تحویل آنی</strong> هستند و همچنین
              در صورت بروز هرگونه مشکلی تا 7 روز در صورت وجود{" "}
              <strong>"هرگونه"</strong> مشکلی در پلاگین های سایت امکان عودت وجه
              و پشتیبانی از طریق ایمیل وجود دارد.
            </p>
          </div>
          <img
            className="rounded-xl h-[200px] object-cover w-[600px]"
            src="https://thumb.jobinjacdn.com/1s1fYYXHIe9REFuCbNu6spvU7FQ=/768x/filters:strip_exif():stretch():quality(100)/https://storage.jjcloud.ir/other/files/images/85d610ed7068a14bf85de715c3c261badf0e1b07/wE__cec6f62cfb44b1be110b7bf70c8362d8/companies_media/main.jpg"
          />
        </div>
      </div>
    </Layout>
  );
};
